import React from 'react';
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';
import { Text, mediaQueries } from '@reservamos/elements';

const AutocompleteItem = ({ index, item, getItemProps }) => {
  const { colors } = useTheme();
  const Item = css`
    padding: 15px 20px;
    list-style: none;
    cursor: pointer;
    &:hover {
      background-color: ${colors.gray100};
    }

    ${mediaQueries.small} {
      padding: 20px;
    }
  `;
  const resultType = (resultItem) => {
    if (resultItem.resultType === 'city') {
      return (
        <>
          <Text color="gray600" size="M" mobileSize="S">
            {resultItem.display}
          </Text>
          <span style={{ color: 'red' }}>Todas las terminales</span>
        </>
      );
    }
    return (
      <>
        <Text color="gray600" size="M" mobileSize="S">
          {resultItem.name}
        </Text>
        {/* <span style={{ color: 'red' }}>{resultItem.name}</span> */}
      </>
    );
  };

  return (
    <div css={Item}>
      <li
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getItemProps({ item, index })}
      >
        {resultType(item)}
      </li>
    </div>
  );
};

AutocompleteItem.propTypes = {
  getItemProps: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.shape({
    cityName: PropTypes.string,
    display: PropTypes.string,
    id: PropTypes.number,
    resultType: PropTypes.string,
    slug: PropTypes.string,
  }).isRequired,
};

export default AutocompleteItem;
