import React from 'react';
import PropTypes from 'prop-types';
import {
  Spacing,
  Text,
  Icon,
  mediaQueries,
  Currency,
} from '@reservamos/elements';
import { css, useTheme } from '@emotion/react';
import assetUrl from '../../utils/assetUrl';

const ImageCard = ({
  imageSrc,
  departure,
  arrival,
  price,
  imageAlt,
  onClick,
  href,
  fullImage,
}) => {
  const { colors } = useTheme();
  const container = css`
    background-color: ${colors.white};
    padding: 10px;
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: -13px;
    ${mediaQueries.small} {
      max-height: 100px;
    }
  `;
  const image = css`
    width: 100%;
    height: 100%;
    object-fit: cover;
  `;
  const priceWrapper = css`
    align-self: flex-end;
    margin: 0px;
  `;
  const imageContainer = css`
    width: ${fullImage ? '400px' : '180px'};
    height: 120px;
    border-radius: 10px;
    overflow: hidden;
    ${mediaQueries.medium} {
      width: 180px;
    }
    ${mediaQueries.small} {
      width: 150px;
      height: 100px;
    }
  `;
  const principal = css`
    display: flex;
    cursor:pointer;
    box-shadow: 5px 5px 10px #00000020;
    border-radius: 10px;
    overflow: hidden
    }
  `;

  return (
    <>
      <a href={href} onClick={onClick}>
        <div css={principal}>
          <div css={imageContainer}>
            <img src={imageSrc} alt={imageAlt} css={image} />
          </div>
          <div css={container}>
            <Spacing vertical fullWidth>
              <Spacing vertical size="S">
                <Spacing alignItems="center" size="XS">
                  <Icon
                    size="M"
                    mobileSize="S"
                    type={assetUrl('icons/departure.svg')}
                  />
                  <Text color="gray500" mobileSize="S">
                    {departure}
                  </Text>
                </Spacing>
                <Spacing alignItems="center" size="XS">
                  <Icon
                    size="M"
                    mobileSize="S"
                    type={assetUrl('icons/arrival.svg')}
                  />
                  <Text color="gray500" mobileSize="S">
                    {arrival}
                  </Text>
                </Spacing>
              </Spacing>
              <div css={priceWrapper}>
                <Text
                  fontFamily="secondary"
                  color="gray500"
                  size="S"
                  mobileSize="XS"
                >
                  Desde
                </Text>
                <Currency
                  price={price}
                  currency="MXN"
                  decimals="0"
                  color="primaryStrong"
                  size="L"
                  weight="bold"
                  mobileSize="S"
                />
              </div>
            </Spacing>
          </div>
        </div>
      </a>
    </>
  );
};

ImageCard.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  departure: PropTypes.string.isRequired,
  arrival: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  href: PropTypes.string.isRequired,
  fullImage: PropTypes.bool,
};

ImageCard.defaultProps = {
  onClick: () => {},
  fullImage: false,
};
export default ImageCard;
