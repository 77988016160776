import React from 'react';
import { ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';
import DatePickerItem from '../ui/molecules/DatePickerItem';
import Autocomplete from '../ui/molecules/Autocomplete';
import 'react-datepicker/dist/react-datepicker.css';
import * as typography from '../src/tokens/typography';

const SearchFieldsDesktop = ({ setFieldValue, values }) => {
  const { colors } = useTheme();
  const container = css`
    position: relative;
  `;
  const error = css`
    color: ${colors.error};
    font-size: ${typography.XS};
    position: absolute;
    top: -15px;
    right: 5px;
  `;
  return (
    <>
      <div css={container}>
        <Autocomplete
          iconInput="vanda/icons/departure-gray.svg"
          name="origin"
          setFieldValue={setFieldValue}
          onChange={() => {
            setFieldValue('destination', '');
            // TODO: aquí pasa algo para borrar el value del otro autocomplete
          }}
          placeholder="Origen"
          requestParams=""
        />
        <ErrorMessage name="origin" component="span" css={error} />
      </div>
      <div css={container}>
        <Autocomplete
          iconInput="vanda/icons/arrival-gray.svg"
          name="destination"
          setFieldValue={setFieldValue}
          placeholder="Destino"
          requestParams={values.origin}
          disabled={!values.origin}
          // TODO: otra prop que depende del on change del otro autocomplete
        />
        <ErrorMessage name="destination" component="span" css={error} />
      </div>
      {process.env.NEXT_PUBLIC_BRAND !== 'vanda' && (
        <DatePickerItem
          setFieldValue={setFieldValue}
          departureDate={values.departureDate}
        />
      )}
    </>
  );
};

SearchFieldsDesktop.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    origin: PropTypes.string.isRequired,
    destination: PropTypes.string.isRequired,
    departureDate: PropTypes.string.isRequired,
  }).isRequired,
};

export default SearchFieldsDesktop;
