import React from 'react';
import { Spacing, Text, mediaQueries } from '@reservamos/elements';
import { css, useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import assetUrl from '../../../utils/assetUrl';

const DestinationsRB = ({
  descriptionText1,
  descriptionText2,
  descriptionText3,
  descriptionText4,
  href1,
  href2,
  href3,
  href4,
}) => {
  const { colors } = useTheme();
  const image = css`
    max-width: 250px;
    box-shadow: 2px 2px 5px ${colors.gray300};
    ${mediaQueries.medium} {
      max-width: 180px;
      max-height: 180px;
    }
  `;
  return (
    <Spacing vertical size="L">
      <Text weight="bold" size="XXL">
        Viajes especiales a eventos
      </Text>
      <Spacing
        isResponsive
        flexGrow
        alignItems="center"
        justifyContent="space-between"
        responsiveSize="XL"
      >
        <a href={href1} target="_blanck">
          <Spacing vertical>
            <img
              css={image}
              src={assetUrl('main/corona.png')}
              alt="Corona capital guadalajara"
            />
            <Text weight="semibold">{descriptionText1}</Text>
          </Spacing>
        </a>
        <a href={href2} target="_blanck">
          <Spacing vertical>
            <img
              css={image}
              src={assetUrl('main/corona.png')}
              alt="Corona capital guadalajara"
            />
            <Text weight="semibold">{descriptionText2}</Text>
          </Spacing>
        </a>
        <a href={href3} target="_blanck">
          <Spacing vertical>
            <img
              css={image}
              src={assetUrl('main/corona.png')}
              alt="Corona capital guadalajara"
            />
            <Text weight="semibold">{descriptionText3}</Text>
          </Spacing>
        </a>
        <a href={href4} target="_blanck">
          <Spacing vertical>
            <img
              css={image}
              src={assetUrl('main/corona.png')}
              alt="Corona capital guadalajara"
            />
            <Text weight="semibold">{descriptionText4}</Text>
          </Spacing>
        </a>
      </Spacing>
    </Spacing>
  );
};
DestinationsRB.propTypes = {
  descriptionText1: PropTypes.string,
  descriptionText2: PropTypes.string,
  descriptionText3: PropTypes.string,
  descriptionText4: PropTypes.string,
  href1: PropTypes.string,
  href2: PropTypes.string,
  href3: PropTypes.string,
  href4: PropTypes.string,
};
DestinationsRB.defaultProps = {
  descriptionText1: '',
  descriptionText2: '',
  descriptionText3: '',
  descriptionText4: '',
  href1: '',
  href2: '',
  href3: '',
  href4: '',
};

export default DestinationsRB;
