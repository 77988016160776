import React from 'react';
import { Form, Formik } from 'formik';
import { Button, Spacing, mediaQueries } from '@reservamos/elements';
import * as Yup from 'yup';
import { useRouter } from 'next/router';
import { css } from '@emotion/react';
import SearchFieldsDesktop from './SearchFieldsDesktop';
import SearchFieldsMobile from './SearchFieldsMobile';
import 'react-datepicker/dist/react-datepicker.css';
import useWindowSize from '../hooks/useWindowSize';
import { customFormat } from '../utils/formatDate';

const SearchFormAuto = () => {
  const { isMobile } = useWindowSize();
  const button = css`
    min-width: 200px;
    margin-top: 2px;
    ${mediaQueries.small} {
      margin-top: 10px;
    }
  `;

  const router = useRouter();

  return (
    <Formik
      initialValues={{
        origin: '',
        destination: '',
        departureDate: new Date(),
      }}
      onSubmit={({ origin, destination, departureDate }) => {
        if (process.env.NEXT_PUBLIC_BRAND === 'vanda') {
          router.push(
            `/trips?originCity=${origin}&destinationCity=${destination}`
          );
        } else {
          router.push(
            `/trips?originCity=${origin}&destinationCity=${destination}&departureDate=${customFormat(
              departureDate
            )}`
          );
        }
      }}
      validationSchema={Yup.object({
        origin: Yup.string().required('Required'),
        destination: Yup.string().required('Required'),
        departureDate: Yup.string(),
      })}
    >
      {({ values, setFieldValue }) => {
        return (
          <Form autoComplete="off">
            <Spacing size="S" isResponsive flexGrow fullWidth>
              {isMobile ? (
                <SearchFieldsMobile
                  values={values}
                  setFieldValue={setFieldValue}
                />
              ) : (
                <SearchFieldsDesktop
                  values={values}
                  setFieldValue={setFieldValue}
                />
              )}

              <div css={button}>
                <Button
                  text="Buscar"
                  variant="accent"
                  buttonType="submit"
                  minHeight="60px"
                  fullWidth
                />
              </div>
            </Spacing>
          </Form>
        );
      }}
    </Formik>
  );
};

export default SearchFormAuto;
