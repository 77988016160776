import React from 'react';
import PropTypes from 'prop-types';
import { mediaQueries } from '@reservamos/elements';
import { css } from '@emotion/react';

const GridCard = ({ children, isTwoColumns, isOneRow }) => {
  const container = css`
    display: grid;
    grid-gap: 20px;
    grid-template-columns: ${isTwoColumns ? '1fr 1fr' : '1fr 1fr 1fr'};
    grid-template-rows: ${isOneRow ? '1fr' : ' 1fr 1fr'};

    ${mediaQueries.medium} {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: ${isOneRow ? ' 1fr' : ' 1fr 1fr 1fr'};
      padding: 0 10px;
    }

    ${mediaQueries.small} {
      grid-gap: 5px;
      grid-template-columns: 1fr;
    }
  `;
  return <div css={container}>{children}</div>;
};

GridCard.propTypes = {
  /** Container's content */
  children: PropTypes.node.isRequired,
  /** Defines if cards are displayed in two columns for desktop */
  isTwoColumns: PropTypes.bool,
  isOneRow: PropTypes.bool,
};

GridCard.defaultProps = {
  isTwoColumns: false,
  isOneRow: false,
};

export default GridCard;
