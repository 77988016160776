import React from 'react';
import { ErrorMessage } from 'formik';
import { Spacing, Icon } from '@reservamos/elements';
import PropTypes from 'prop-types';
import { css, useTheme } from '@emotion/react';
import SearchModal from './SearchModal';
import DatePickerItem from '../ui/molecules/DatePickerItem';
import * as typography from '../src/tokens/typography';

const SearchFieldsMobile = ({ setFieldValue, values }) => {
  const { colors, fonts } = useTheme();

  const input = css`
    border-radius: 10px;
    padding: 20px 20px 20px 0px;
    width: 100%;
    color: ${colors.primaryStrong};
    font-family: ${fonts.primary};
    font-size: ${typography.M};
    border-width: 0;
    background-size: 20px;
    background-position: 10px 50%;
    background-repeat: no-repeat;
    background-color: ${colors.white};
    max-height: 60px;
    &:focus {
      outline: 2px solid ${colors.primary};
    }
  `;

  const container = css`
    position: relative;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0px 0px 5px ${colors.gray200};
    background-color: ${colors.white};
  `;

  return (
    <>
      <Spacing vertical size="S">
        <SearchModal
          openSelect
          modalTrigger={
            <div css={container}>
              <Spacing size="S" justifyContent="center" alignItems="center">
                <Icon
                  hasMargin
                  marginSide="left"
                  size="M"
                  type="vanda/icons/departure-gray.svg"
                />
                <input
                  placeholder="Origen"
                  name="originDisplay"
                  value={values.originDisplay}
                  css={input}
                  readOnly
                />
              </Spacing>
            </div>
          }
          inputDisplayName="originDisplay"
          inputName="origin"
          setFieldValue={setFieldValue}
          placeholder="Origen"
        />
        <input type="hidden" name="origin" value={values.origin} />
        <ErrorMessage name="origin" component="span" />

        <SearchModal
          openSelect
          modalTrigger={
            <div css={container}>
              <Spacing size="S" justifyContent="center" alignItems="center">
                <Icon
                  hasMargin
                  marginSide="left"
                  size="M"
                  type="vanda/icons/arrival-gray.svg"
                />
                <input
                  placeholder="Destino"
                  name="destination"
                  value={values.destinationDisplay}
                  css={input}
                  readOnly
                />
              </Spacing>
            </div>
          }
          inputDisplayName="destinationDisplay"
          inputName="destination"
          setFieldValue={setFieldValue}
          placeholder="Destino"
          originSelectedValue={values.origin}
        />

        {process.env.NEXT_PUBLIC_BRAND !== 'vanda' && (
          <DatePickerItem
            setFieldValue={setFieldValue}
            departureDate={values.departureDate}
          />
        )}

        <input
          type="hidden"
          name="destinationDisplay"
          value={values.destination}
        />
        <ErrorMessage name="destination" component="span" />
      </Spacing>
    </>
  );
};

SearchFieldsMobile.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({
    origin: PropTypes.string.isRequired,
    originDisplay: PropTypes.string.isRequired,
    destination: PropTypes.string.isRequired,
    destinationDisplay: PropTypes.string.isRequired,
    departureDate: PropTypes.string.isRequired,
  }).isRequired,
};

export default SearchFieldsMobile;
