import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { css, useTheme } from '@emotion/react';
// import { mediaQueries } from '@reservamos/elements';
import deburr from 'lodash/deburr';
import { useCombobox } from 'downshift';
import PropTypes from 'prop-types';
import CITIES from '../../queries/cities';
import MEETINGPOINTS from '../../queries/meetingPoints';
import AutocompleteList from './AutocompleteList';
import * as typography from '../../src/tokens/typography';

const Autocomplete = ({
  iconInput,
  name,
  setFieldValue,
  requestParams,
  disabled,
  onChange,
  placeholder,
  onItemSelect,
  openSelect,
}) => {
  const { colors, fonts } = useTheme();
  const container = css`
    position: relative;
    border-radius: 10px;
    background-color: ${colors.white};
    width: 100%;
    margin-top: 2px;
    box-shadow: 0px 0px 5px ${colors.gray200};
  `;
  const input = css`
    border-radius: 10px;
    padding: 20px 20px 20px 35px;
    width: 100%;
    color: ${colors.primaryStrong};
    font-family: ${fonts.primary};
    font-size: ${typography.M};
    border-width: 0;
    background-image: url(${iconInput});
    background-size: 20px;
    background-position: 10px 50%;
    background-repeat: no-repeat;
    background-color: ${colors.white};
    max-height: 60px;
    &:focus {
      outline: 2px solid ${colors.primary};
    }
  `;

  const [items, setItems] = useState([]);
  const [display, setDisplay] = useState('');

  const { loading, data } = useQuery(
    process.env.NEXT_PUBLIC_BRAND === 'vanda' ? CITIES : MEETINGPOINTS,
    {
      variables: { from: requestParams },
      onCompleted: (initialData) => {
        setItems(
          process.env.NEXT_PUBLIC_BRAND === 'vanda'
            ? initialData.cities
            : initialData.meetingPoints
        );
      },
    }
  );

  const {
    getComboboxProps,
    getInputProps,
    getMenuProps,
    isOpen,
    getItemProps,
    openMenu,
    closeMenu,
  } = useCombobox({
    items,
    onInputValueChange({ inputValue }) {
      setDisplay(inputValue);
      const arrayHelper =
        process.env.NEXT_PUBLIC_BRAND === 'vanda'
          ? data.cities
          : data.meetingPoints;
      setItems(
        arrayHelper.filter((item) => {
          return (
            !inputValue ||
            deburr(item.name.toLowerCase()).includes(
              deburr(inputValue.toLowerCase())
            )
          );
        })
      );
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) return setFieldValue(name, '');
      setFieldValue(name, selectedItem.slug);
      closeMenu();
      onItemSelect(selectedItem);
      return name;
    },
    onIsOpenChange: ({ highlightedIndex2 }) => {
      if (highlightedIndex2 === -1) {
        setFieldValue(name, '');
      }
    },
    itemToString: (item) => item?.name ?? '',
    defaultHighlightedIndex: 0,
    initialIsOpen: openSelect,
  });

  const handleFocus = (e) => {
    e.target.select();
    openMenu();
  };

  return (
    <div css={container}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <div {...getComboboxProps()}>
        <input
          placeholder={placeholder}
          name={name}
          css={input}
          disabled={disabled || data?.cities?.length < 1 || loading}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getInputProps({
            onChange,
            onFocus: handleFocus,
            value: display,
          })}
          type="search"
        />

        <AutocompleteList
          getMenuProps={getMenuProps}
          isOpen={isOpen}
          loading={loading}
          items={items}
          getItemProps={getItemProps}
        />
      </div>
    </div>
  );
};

Autocomplete.propTypes = {
  iconInput: PropTypes.string.isRequired,
  cache: PropTypes.bool,
  debounceTime: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  queryParam: PropTypes.string,
  requestParams: PropTypes.objectOf(PropTypes.string),
  transform: PropTypes.func,
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func,
  disabled: PropTypes.bool,
  onItemSelect: PropTypes.func,
  openSelect: PropTypes.bool.isRequired,
};

Autocomplete.defaultProps = {
  cache: true,
  debounceTime: 250,
  placeholder: 'Placeholder',
  queryParam: 'q',
  requestParams: {},
  transform: (data) => data,
  disabled: false,
  setFieldValue: () => {},
  onItemSelect: () => {},
};

export default Autocomplete;
