import React, { useState } from 'react';
import Slider from 'react-slick';
import { mediaQueries, Spacing } from '@reservamos/elements';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { css, useTheme } from '@emotion/react';
import LayoutContainer from '../atoms/LayoutContainer';
import { firstLayer } from '../../src/tokens/zindexes';
import SliderItem from './SliderItem';

const SliderVanda = () => {
  const [nav1, setNav1] = useState(null);

  const { colors } = useTheme();

  const wrapper = css`
    button::before,
    button::after {
      opacity: 0.8;
      font-size: 30px;
      color: ${colors.primary};

      ${mediaQueries.small} {
        font-size: 20px;
      }
    }
    .slick-prev {
      left: 10px;
      z-index: ${firstLayer};
    }
    .slick-next {
      right: 20px;
      ${mediaQueries.small} {
        right: 10px;
      }
    }
  `;

  return (
    <div css={wrapper}>
      <LayoutContainer>
        <Spacing vertical size="L">
          <Slider asNavFor={nav1} ref={(slider) => setNav1(slider)}>
            <SliderItem
              hasGif
              leftImage="shared/home/bannerText.png"
              rightImage="shared/home/cobertura.png"
              alt="Cobertura Vanda"
              backImage="shared/home/return.jpg"
            />
            <SliderItem
              src="shared/home/bannerDesktop.jpg"
              alt="Cobertura Vanda"
            />
          </Slider>
        </Spacing>
      </LayoutContainer>
    </div>
  );
};

export default SliderVanda;
