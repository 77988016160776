import React from 'react';
import { Spacing, FlatButton } from '@reservamos/elements';
import { useTranslation } from 'next-i18next';
import Layout from './Layout';
import LayoutContainer from '../ui/atoms/LayoutContainer';
import Message from '../ui/molecules/Message';
import GridCard from '../ui/molecules/GridCard';
import Hero from '../ui/molecules/Hero';
import HomeMarketingContent from '../ui/molecules/HomeMarketingContent';
import CardsTrips from '../ui/molecules/CardsTrips';
import CovidModal from '../ui/organisms/CovidModal';
import Question from '../ui/molecules/Question';
import assetUrl from '../utils/assetUrl';
import SearchFormAuto from './SearchFormAuto';
import SliderVanda from '../ui/molecules/SliderVanda';
import { vandaLinks } from '../constants/links';

const HomeVanda = () => {
  const { t } = useTranslation('home');

  return (
    <Layout hasPhone={false}>
      <Spacing vertical responsiveSize="S">
        <Hero
          isVerticalInMobile="true"
          imageSrc="https://rsrvms-vanda-prod.s3.amazonaws.com/index/vanda-bus-index.png"
          imageAlt="Autobús Vanda"
          title={t('title', { context: process.env.NEXT_PUBLIC_BRAND })}
          heroBackground="vanda/hero/background.svg"
        >
          <SearchFormAuto />
        </Hero>
        <LayoutContainer>
          <Spacing vertical responsiveSize="S">
            <GridCard isOneRow>
              <CardsTrips isHome />
            </GridCard>
            <SliderVanda />
            <HomeMarketingContent videoSrc={vandaLinks.marketingVideo} />
            <Spacing isResponsive size="S">
              <Message
                title="Contamos con protocolos de bioseguridad"
                icon={assetUrl('icons/facemask.svg')}
              >
                <CovidModal
                  modalTrigger={
                    <FlatButton
                      type="info"
                      text="Ver todas las medidas sanitarias"
                      size="S"
                    />
                  }
                />
              </Message>
              <Message
                icon={assetUrl('icons/faq.svg')}
                title="¿Tienes alguna pregunta?"
              >
                <Question />
              </Message>
            </Spacing>
          </Spacing>
        </LayoutContainer>
      </Spacing>
    </Layout>
  );
};

export default HomeVanda;
