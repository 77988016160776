import React from 'react';
import PropTypes from 'prop-types';
import { LoaderDots, Text, mediaQueries } from '@reservamos/elements';
import { css, useTheme } from '@emotion/react';
import AutocompleteItem from './AutocompleteItem';

const AutocompleteList = ({
  getItemProps,
  getMenuProps,
  isOpen,
  items,
  loading,
}) => {
  let content = null;
  const { colors } = useTheme();
  const autocompleteDropdown = css`
    margin-top: 10px;
    min-width: 400px;
    position: absolute;
    border-radius: 10px;
    box-shadow: 0px 0px 5px ${colors.gray200};
    background-color: ${colors.white};
    overflow: hidden;
    ${mediaQueries.small} {
      min-width: 100%;
    }
    ul {
      padding: 0;
      margin: 0;
    }
  `;

  const emptyItem = css`
    padding: 15px 20px;
  `;

  if (isOpen) {
    if (loading) {
      content = <LoaderDots isAccent />;
    } else if (items.length) {
      content = items.map((item, index) => (
        <AutocompleteItem
          getItemProps={getItemProps}
          item={item}
          index={index}
          key={item.id}
        />
      ));
    } else {
      content = (
        <div css={emptyItem}>
          <Text color="accent" size="M" mobileSize="S">
            Sin resultados
          </Text>
        </div>
      );
    }
  }
  return (
    <div css={autocompleteDropdown}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <ul {...getMenuProps()}>{content}</ul>
    </div>
  );
};

AutocompleteList.propTypes = {
  getMenuProps: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      cityName: PropTypes.string,
      display: PropTypes.string,
      id: PropTypes.number,
      resultType: PropTypes.string,
      slug: PropTypes.string,
    })
  ).isRequired,
  getItemProps: PropTypes.func.isRequired,
};

export default AutocompleteList;
